import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';

export default function Widget({style, children, center, padding, ...props}) {
  const styles = {
    paddingLeft: padding,
    paddingRight: padding,
    paddingBottom: 0,
    paddingTop: 0,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: center ? 'center' : '',
    height: 48,
    ...style,
  };
  return (
    <Paper style={styles} elevation={0} {...props} >
      {children}
    </Paper>
  );
}

Widget.defaultProps = {
  padding: 8,
  children: 'Widget',
  center: false,
};

Widget.propTypes = {
  children: PropTypes.node,
  padding: PropTypes.number,
  center: PropTypes.bool,
  style: PropTypes.object,
};