//https://github.com/domoticz/Reacticz/tree/master/src/widgets
import React from 'react';
import { useSelector } from 'react-redux'
// import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import SwitchOnOff from './widgets/SwitchOnOff';
import SwitchDimmer from './widgets/SwitchDimmer';
import SwitchScene from './widgets/SwitchScene';
import SwitchGroup from './widgets/SwitchGroup';
import Thermostat from './widgets/Thermostat';
import SwitchSelector from './widgets/SwitchSelector';
import Lux from './widgets/Lux';
import Phone from './widgets/Phone';
import SecurityPanel from './widgets/SecurityPanel';
import Download from './widgets/Download';
import Upload from './widgets/Upload';
import MotionSensor from './widgets/MotionSensor';
import GenericDevice from './widgets/GenericDevice';
import Temperature from './widgets/Temperature';
import MeterGas from './widgets/MeterGas';
import MeterEnergy from './widgets/MeterEnergy';
import SwitchContact from './widgets/SwitchContact';
import MeterUsage from './widgets/MeterUsage';
import SmokeDetector from './widgets/SmokeDetector';

export default function DomoticzDevice({device, gridItem=true, style}) {
  const myDevice = useSelector(devices => devices.find((thisDevice)=>thisDevice.idx === device.idx));
  const DeviceWidget = getDeviceWidget(myDevice);
  const gridSize = getDeviceGridSize(myDevice);

  return (
    <GridItem gridSize={gridSize} gridItem={gridItem} style={style}>
      <DeviceWidget device={myDevice} gridItem={gridItem} />
    </GridItem>
  );
}

function GridItem({gridItem, gridSize, style, children}) {
  if(gridItem) {
    return (
      <Grid item {...gridSize} style={style}>
        {children}
      </Grid>
    );
  } else {
    return children;
  }
}

function getDeviceWidget(device) {
  switch (device.SwitchType) {
    case 'Blinds' :
    case 'Blinds Inverted' :
    case 'Venetian Blinds EU' :
    case 'Venetian Blinds US' :
      return GenericDevice;
    case 'Contact' :
    case 'Door Contact' :
    case 'Door Lock' :
    case 'Door Lock Inverted' :
      return SwitchContact;
    case 'Dimmer' :
      return SwitchDimmer;
    case 'Media Player' :
      return GenericDevice;
    case 'Motion Sensor' :
      return MotionSensor;
    case 'On/Off' :
      switch(device.Image) {
        case 'Phone' :
          return Phone;
        default:
          break;
      }
      return SwitchOnOff;
    case 'Push Off Button' :
    case 'Push On Button' :
      return GenericDevice;
    case 'Selector' :
      return SwitchSelector;
    case 'Smoke Detector' :
      return SmokeDetector;
    default :
      break;
  }
  switch (device.Type) {
    case 'General' :
      switch (device.SubType) {
        case 'Alert' :
          return GenericDevice;
        case 'Custom Sensor' :
          switch (device.Name) {
              case 'Download' :
                return Download;
              case 'Upload' :
                return Upload;
              default : 
                break;
          }
          break;
        case 'kWh' :
        case 'Percentage' :
        case 'Solar Radiation' :
        case 'Text' :
        case 'Visibility' :
          return GenericDevice;
        default :
          break;
      }
      break;
    case 'Heating' :
    case 'Thermostat' :
      return Thermostat;
    case 'P1 Smart Meter' :
      switch (device.SubType) {
        case 'Energy' :
          return MeterEnergy;
        case 'Gas' :
          return MeterGas;
        default :
          break;
      }
      break;
    case 'Lux' :
      return Lux;
    case 'Humidity' :
    case 'Rain' :
    case 'RFXMeter' :
//         switch (device.meterType) {
//           case 'Gas' :
//           case 'Counter':
//           case 'Energy' :
//           case 'Energy Generated' :
//           case 'Water' :
//           case 'Unknown':
//           default :
//             break;
//         }
      return GenericDevice;
    case 'Temp' :
    case 'Temp + Humidity' :
    case 'Temp + Humidity + Baro' :
      return Temperature;
    case 'Usage' :
      switch (device.SubType) {
        case 'Electric' :
          return MeterUsage;
        default :
          break;
      }
      break;
    case 'UV' :
    case 'Wind' :
      return GenericDevice;
    case 'Security':
      return SecurityPanel;
    case 'Scene' :
      return SwitchScene;
    case 'Group' :
      return SwitchGroup;  
    default :
      break;
  }  
  return GenericDevice;
}

function getDeviceGridSize(device) {
  switch (device.SwitchType) {
    case 'Blinds' :
    case 'Blinds Inverted' :
    case 'Venetian Blinds EU' :
    case 'Venetian Blinds US' :
    case 'Contact' :
    case 'Door Contact' :
    case 'Door Lock' :
    case 'Door Lock Inverted' :
      break;
    case 'Dimmer' :
      //if (device.stype === 'RGBW') {}
      return {xs: 12, sm: 6, lg: 3};
    case 'Media Player' :
    case 'Motion Sensor' :
      break;
    case 'On/Off' :
      switch(device.Image) {
        case 'Phone' :
          return {xs: 3, sm: 3};
        default:
          break;
      }
    break;
    case 'Push Off Button' :
    case 'Push On Button' :
      break;
    case 'Selector' :
      return {xs: 12, sm: 6, lg: 3};
    case 'Smoke Detector' :
    default :
      break;
  }
  switch (device.Type) {
    case 'General' :
      switch (device.SubType) {
        case 'Alert' :
        case 'Custom Sensor' :
          switch (device.Name) {
              case 'Download' :
              case 'Upload' :
              default : 
                break;
          }
          break;
        case 'kWh' :
        case 'Percentage' :
        case 'Solar Radiation' :
        case 'Text' :
        case 'Visibility' :
        default :
          break;
      }
      break;
    case 'Heating' :
    case 'Thermostat' :
    case 'P1 Smart Meter' :
      switch (device.SubType) {
        case 'Energy' :
        case 'Gas' :
        default :
          break;
      }
      break;
    case 'Lux' :
      return {xs: 6, sm: 6, md: 3};
    case 'Humidity' :
    case 'Rain' :
    case 'RFXMeter' :
//         switch (device.meterType) {
//           case 'Gas' :
//           case 'Counter':
//           case 'Energy' :
//           case 'Energy Generated' :
//           case 'Water' :
//           case 'Unknown':
//           default :
//             break;
//         }
      return GenericDevice;
    case 'Temp' :
    case 'Temp + Humidity' :
    case 'Temp + Humidity + Baro' :
    case 'Usage' :
      switch (device.SubType) {
        case 'Electric' :
        default :
          break;
      }
      break;
    case 'UV' :
    case 'Wind' :
      break;
    case 'Security':
      return {xs: 12, sm: 6, lg: 3};
    case 'Scene' :
    case 'Group' :
    default :
      break;
  }  
  return {xs: 6, sm: 3};
}
